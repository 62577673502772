<template>
  <div class="self-center">
    <b-row>
      <b-modal
        v-model="preview_job"
        centered
        hide-footer
        title="Job Preview"
      >
        <list-event
          v-if="preview_job"
          class="w-full"
          :inherited-event-i-d="preview_event_id"
        />
      </b-modal>
    </b-row>
    <div class="d-flex flex-row flex-wrap">

      <b-button
        v-if="params.table === 'events'"
        v-b-tooltip.hover.top="'Click here to manage all the participating jobs.'"
        class="btn-icon grid-btn"
        variant="outline-dark"
        @click="showParticipatingEvents()"
      >
        <feather-icon
          icon="Maximize2Icon"
          class=""
          svg-classes="hover:text-primary stroke-current"
        />
      </b-button>

      <b-button
        v-if="
          params.publication_status && params.publication_status === 'pending'
        "
        v-b-tooltip.hover.top="`Click here to view a preview of the ${default_name.toLowerCase()}.`"
        class="btn-icon grid-btn"
        variant="outline-dark"

        @click="previewJob(params)"
      >
        <feather-icon
          icon="EyeIcon"
          class=""
          svg-classes="hover:text-primary stroke-current"
        />
      </b-button>

      <b-button
        v-else
        v-b-tooltip.hover.top="`Click here to view a preview of the ${default_name.toLowerCase()}.`"
        class="btn-icon grid-btn"
        variant="outline-dark"
        @click="activatePreview()"
      >
        <feather-icon
          icon="EyeIcon"
          class=""
          svg-classes="hover:text-primary stroke-current"
        />
      </b-button>

      <b-button
        v-if="
          params.table === 'events' &&
            !$store.state.auth.ActiveUser.email_address.includes('hhqmail')
        "
        v-b-tooltip.hover.top="`Click here to boost this job and get more applications.`"
        class="btn-icon grid-btn"
        variant="outline-dark"
        @click="boostJob"
      >
        <feather-icon
          icon="ZapIcon"
          class=""
          svg-classes="hover:text-primary stroke-current"
        />
      </b-button>

      <b-button
        v-if="
          params.publication_status && params.publication_status === 'pending'
        "
        v-b-tooltip.hover.top="`Click here to get the ${default_name.toLowerCase()} link and share to different social media platforms.`"
        class="btn-icon grid-btn"
        variant="outline-dark"
        @click="onCopy()"
      >
        <feather-icon
          icon="Share2Icon"
          class=""
          svg-classes="hover:text-primary"
        />
      </b-button>
      <b-button
        v-if="$checkRights(this.$route.meta.section, 'update')"
        v-b-tooltip.hover.top="`Click here to edit this ${default_name.toLowerCase()}.`"
        class="btn-icon grid-btn"
        variant="outline-dark"
        @click="editSpecificEvent()"
      >
        <feather-icon
          icon="EditIcon"
          svg-classes="hover:text-primary"
          class=""
        />
      </b-button>

      <template v-if="$isEmpty(params.data.closed_on) && params.data.active">
        <b-button
          v-if="$checkRights(this.$route.meta.section, 'delete')"
          v-b-tooltip.hover.top="`Click here to close this ${default_name.toLowerCase()}.`"
          class="btn-icon grid-btn"
          variant="outline-dark"
          @click="deleteEvent()"
        >
          <feather-icon
            icon="TrashIcon"
            svg-classes="hover:text-danger"
            class=""
          />
        </b-button>
      </template>
      <template v-else>
        <b-button
          v-if="$checkRights(this.$route.meta.section, 'delete')"
          v-b-tooltip.hover.top="`Click here to restore this ${default_name.toLowerCase()}.`"
          class="btn-icon grid-btn"
          variant="outline-dark"
          @click="restoreEvent()"
        >
          <feather-icon
            icon="RotateCcwIcon"
            svg-classes="hover:text-danger"
            class=""
          />
        </b-button>
      </template>

      <!-- <b-dropdown
        variant="flat-dark"
        class="dropdown-icon-wrapper"
      >
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="18"
            class="align-middle"
          />
        </template>

        <b-dropdown-item
          v-if="params.event_category === 'group'"

          @click="showParticipatingEvents()"
        >
          <div
            v-b-tooltip.hover.top="'Click here to view all the participating jobs.'"
            class="d-flex w-full"
          >
            <feather-icon
              icon="Maximize2Icon"
              class=""
              svg-classes="hover:text-primary stroke-current"
            />
            <p>Manage Jobs</p>
          </div>
        </b-dropdown-item>

        <b-dropdown-item
          v-if="
            params.publication_status && params.publication_status === 'pending'
          "
          style="z-index: 100000!important;"
          @click="activatePreview()"
        >

          <div
            v-b-tooltip.hover.top="`Click here to view a preview of the ${default_name.toLowerCase()}.`"
            class="d-flex"
          >
            <feather-icon
              icon="EyeIcon"
              class=""
              svg-classes="hover:text-primary stroke-current"
            />
            <p>{{ default_name }} Preview</p>
          </div>
        </b-dropdown-item>

        <b-dropdown-item
          v-else
          @click="previewJob(params)"
        >

          <div
            v-b-tooltip.hover.top="`Click here to view a preview of the ${default_name.toLowerCase()}.`"
            class="d-flex"
          >
            <feather-icon
              icon="EyeIcon"
              class=""
              svg-classes="hover:text-primary stroke-current"
            />
            <p>{{ default_name }} Preview</p>
          </div>

        </b-dropdown-item>

        <b-dropdown-item
          v-if="
            params.table === 'events_jobs' &&
              !$store.state.auth.ActiveUser.email_address.includes('hhqmail')
          "
          @click="boostJob"
        >
          <div
            v-b-tooltip.hover.top="`Click here to boost this job and get more applications.`"
            class="d-flex"
          >
            <feather-icon
              icon="ZapIcon"
              class=""
              svg-classes="hover:text-primary stroke-current"
            />
            <p>Boost {{ default_name }}</p>
          </div>
        </b-dropdown-item>

        <b-dropdown-item
          v-if="
            params.approved === true ||
              params.approved === undefined
          "
          @click="onCopy()"
        >

          <div
            v-b-tooltip.hover.top="`Click here to get the ${default_name.toLowerCase()} link and share to different social media platforms.`"
            class="d-flex"
          >
            <feather-icon
              icon="Share2Icon"
              class=""
              svg-classes="hover:text-primary"
            />
            <p>Copy {{ default_name }} Link</p>
          </div>
        </b-dropdown-item>

        <b-dropdown-item @click="editSpecificEvent()">
          <div
            v-b-tooltip.hover.top="`Click here to edit this ${default_name.toLowerCase()}.`"
            class="d-flex"
          >
            <feather-icon
              icon="EditIcon"
              svg-classes="hover:text-primary"
              class=""
            />
            <p>Edit {{ default_name }} Details</p>
          </div>
        </b-dropdown-item>

        <b-dropdown-item @click="deleteEvent()">

          <div
            v-b-tooltip.hover.top="`Click here to close this ${default_name.toLowerCase()}.`"
            class="d-flex"
          >
            <feather-icon
              icon="TrashIcon"
              svg-classes="hover:text-danger"
              class=""
            />
            <p>Close {{ default_name }}</p>
          </div>
        </b-dropdown-item>

      </b-dropdown> -->
    </div>
    <b-modal
      v-model="boost_popup"
      centered
      hide-footer
      title="Receive More Applicants To Your Job Listings"
    >
      <div>
        <div class="vx-row my-4 ">
          <b-card class="p-5">
            <b-form-radio
              v-model="app_boost_gurantee"
              class=""
              value="application"
            >By Applications</b-form-radio>
            <div
              v-if="show_options.application"
              class="vx-row ml-2 mt-4"
            >
              <b-alert
                active="true"
                variant="warning"
                icon-pack="feather"
                icon="icon-info"
                class="h-auto"
              >
                <span>Tentatively Around S$0.40 - S$3.00 per application.</span>
              </b-alert>
            </div>
          </b-card>
        </div>

        <div class="vx-row my-4 ">
          <b-card class="p-5 w-full">
            <b-form-radio
              v-model="app_boost_gurantee"
              class=""
              value="guaranteed"
            >By Guaranteed Placements</b-form-radio>
            <div
              v-if="show_options.guaranteed"
              class="vx-row w-full"
            >
              <div class="vx-row my-6 w-full mx-auto">
                <b-form-input
                  v-model="guaranteed_amount"
                  class="w-full ml-6"
                  placeholder="How much are you willing to pay for each placement?"
                />
              </div>

              <div class="vx-row my-6 w-full mx-auto">
                <b-form-input
                  v-model="guaranteed_period"
                  class="w-full ml-6"
                  onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                  maxlength="8"
                  placeholder="guaranteed placement period in days"
                />
              </div>
              <div
                v-if="guaranteed_period > 0"
                class="vx-row my-6 w-full mx-auto"
              >
                <b-form-textarea
                  v-model="payment_terms"
                  rows="4"
                  placeholder="Payment terms for the guaranteed placement period"
                  class="w-full ml-6"
                />
              </div>
            </div>
          </b-card>
        </div>
        <div class="x-row w-full my-6">
          <div class="vx-col w-1/2 mx-auto">
            <b-button
              v-if="!$store.state.auth.ActiveUser.email_address.includes('hhqmail')"
              variant="primary"
              class="w-64"
              @click="boostJobsBackend"
            >Boost Job</b-button>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal
      v-model="social_popup"
      centered
      hide-footer
      title="Share On Social Media Platforms"
    >
      <div class="d-flex flex-column">
        <h6 class="mx-auto">
          The link that will be shared.
        </h6>
        <b-form-input
          v-model="share_link"
          class="mt-1 mx-auto"
        />
      </div>
      <div
        v-if="specified_event !== null"
        class="d-flex flex-wrap justify-content-center p-2"
      >
        <ShareNetwork
          v-for="(info, index) of all_share_icons"
          :key="index"
          class="mx-1"
          :network="info.network"
          :url="share_link"
          :title="
            'Check out this ' +
              specified_event.name +
              ' event organised by ' +
              specified_event.company +
              ' via HeadHunters HQ.'
          "
        >
          <font-awesome-icon
            :icon="info.name"
            size="3x"
            :class="info.styling"
          />
        </ShareNetwork>
      </div>
    </b-modal>

    <b-modal
      v-model="restore_event_popup"
      centered
      hide-footer
      title="Restore Event"
    >
      <b-overlay
        :show="isLoading"
        rounded="sm"
      >
        <div class="my-2 d-flex flex-column justify-content-center align-items-center w-100">
          <b-button
            variant="primary"
            class="w-64"
            @click="confirmRestoreRecord(params, 'restore-event')"
          > Restore Event only </b-button>

          <br> <b>or</b> <br>

          <b-button
            variant="primary"
            class="w-64"
            @click="confirmRestoreRecord(params, 'restore-event-jobs')"
          > Restore Event along with the Jobs </b-button>
        </div>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>

import {
  BCard,
  BFormInput,
  BFormRadio,
  BButton,
  BRow,
  BModal,
  VBTooltip,
  BAlert,
  BDropdown, BDropdownItem, BDropdownDivider,
} from 'bootstrap-vue'
import ListEvent from '../../ListEvent.vue'

export default {
  name: 'CellRendererActions',

  components: {
    ListEvent,
    BCard,
    BFormRadio,
    BFormInput,
    BButton,
    BRow,
    BModal,
    VBTooltip,
    BAlert,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    params: Object,
  },
  data() {
    return {
      isLoading: false,
      default_name: 'Event',
      social_popup: false,
      restore_event_popup: false,
      share_link: '',
      preview_job: false,
      preview_event_id: '',
      specified_event: null,
      boost_popup: false,
      payment_terms: '',
      app_gurantee: '',
      app_boost_gurantee: '',
      guaranteed_amount: '',
      guaranteed_period: 0,
      payment_terms: '',
      show_options: {
        application: false,
        guaranteed: false,
      },
      all_share_icons: [
        {
          name: ['fab', 'facebook-square'],
          styling: 'facebook-color',
          network: 'facebook',
        },
        {
          name: ['fab', 'twitter-square'],
          styling: 'twitter-color',
          network: 'twitter',
        },
        {
          name: ['fab', 'whatsapp-square'],
          styling: 'whatsapp-color',
          network: 'whatsapp',
        },
        {
          name: ['fab', 'linkedin'],
          styling: 'linkedin-color',
          network: 'linkedin',
        },
        {
          name: ['fab', 'facebook-messenger'],
          styling: 'messenger-color',
          network: 'messenger',
        },
        {
          name: ['fab', 'telegram'],
          styling: 'telegram-color',
          network: 'telegram',
        },
      ],
    }
  },
  watch: {
    app_boost_gurantee(val) {
      if (val === 'guaranteed') {
        this.show_options.guaranteed = true
        this.show_options.application = false
      } else if (val === 'application') {
        this.show_options.application = true
        this.show_options.guaranteed = false
      }
    },
  },
  created() {
    const join_event_id = this.$route.params.event_id

    if (join_event_id || this.params.table === 'events_jobs') {
      this.default_name = 'Job'
    }
  },
  methods: {
    boostJobsBackend() {
      const all_data = {
        type: 'events',
        job_id: this.params.id,
        boost_type: this.show_options,
        guaranteed_period: this.guaranteed_period,
        guaranteed_amount: this.guaranteed_amount,
        payment_terms: this.payment_terms,
      }

      this.$http
        .put('/api/boost-job', { data: all_data })
        .then(response => {
          const { success } = response.data
          if (success) {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Event Manager Alert',
                  icon: 'AlertCircleIcon',
                  text: 'Your job has been boosted successfully',
                  variant: 'success',
                },
              },
              { timeout: this.$longestTimeout },
            )
          }
        })
        .catch(error => {})
      this.boost_popup = false
    },
    boostJob() {
      this.boost_popup = true
    },
    previewJob(item) {
      this.preview_job = true
      this.preview_event_id = `${item.id}_pending`
    },
    activatePreview() {
      if (this.params.table === 'events_jobs') {
        this.share_link = `${window.location.origin}/listing/${this.params.id}`
      } else if (this.params.table === 'events') {
        this.share_link = `${window.location.origin}/listings/${this.params.id}_g`
      } else {
        this.share_link = `${window.location.origin}/listing/${this.params.id}`
      }

      window.open(this.share_link, '_blank')

      // this.$router.push("/events-manager/preview/" + this.params.id);
    },

    onCopy() {
      if (this.params.table === 'events_jobs') {
        this.share_link = `${window.location.origin}/listing/${this.params.id}`
      } else if (this.params.table === 'events') {
        this.share_link = `${window.location.origin}/listings/${this.params.id}_g`
      } else {
        this.share_link = `${window.location.origin}/listing/${this.params.id}`
      }

      this.specified_event = this.params
      // this.copyTextToClipboard(gen_link);
      this.social_popup = true
    },
    copyTextToClipboard(text) {
      if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text)
        return
      }
      navigator.clipboard.writeText(text).then(
        () => {},
        err => {},
      )
    },

    editSpecificEvent() {
      if (this.$route.name === 'participating-jobs') {
        localStorage.setItem('event-id', `${this.$route.params.event_id}_job`)
        this.$router.push(`/events-manager/edit-event/${this.params.id}_job`)
      } else if (this.params.publication_status && this.params.publication_status === 'pending') {
          localStorage.setItem('event-id', `${this.$route.params.event_id}_pending`)
				  this.$router.push(
				    `/events-manager/edit-event/${this.params.id}_pending`,
				  )
      } else {
				  this.$router.push(`/events-manager/edit-event/${this.params.id}`)
      }
    },

    deleteEvent() {
      this.refreshData()
      const item = this.params
      this.current_item = item
      if (item.closed_on) {
        this.$toast(
          {
            component: this.$toastContent,
            props: {
              title: `${this.default_name} Closure`,
              icon: 'AlertCircleIcon',
              text: `You have already closed this ${this.default_name.toLowerCase()}.`,
              variant: 'danger',
            },
          },
          { timeout: this.$longestTimeout },
        )
      } else {
        this.confirmDeleteRecord(item)
      }
    },

    delete_event() {
      this.refreshData()
      let url = `/api/event/${this.params.id}`
      if (this.default_name === 'Job') {
        url = `/api/job/${this.params.id}`
      }
      // alert(url)
      // alert(this.default_name)

      this.$http
        .delete(url)
        .then(response => {
          const { success, message } = response.data

          if (success) {
            this.$toastSuccess('Event Manager Alert', `Your ${this.default_name} has been closed successfully`)

            this.$emit('refresh-records', true)
          } else {
            this.$toastDanger('Event Manager Alert', `${this.default_name} close failed.`)
          }
        })
        .catch(error => {
          this.$handleErrorResponse(error, 'Event Manager Alert', `${this.default_name} close failed.`)
        })
    },

    confirmDeleteRecord(params) {
      const recordName = this.default_name.toLowerCase() === 'event' ? params.data.name : params.data.position

      this.$bvModal
        .msgBoxConfirm(
          `You are about to close the following ${this.default_name.toLowerCase()} : \n ${recordName}`,
          {
            title: 'Confirm Closure Alert',
            size: 'sm',
            okVariant: 'danger',
            okTitle: `Close ${this.default_name}`,
            cancelTitle: 'Cancel',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          },
        )
        .then(value => {
          if (value) {
            this.delete_event()
          }
        })
    },

    restoreEvent() {
      this.refreshData()
      const item = this.params
      this.current_item = item

      if (item.closed_on && !item.active) {
        this.$toastDanger(`${this.default_name} Closure`, `You have already closed this ${this.default_name.toLowerCase()}.`)
        this.isLoading = false
      }

      if (this.default_name === 'Event') {
        this.showRestoreEventPopup(item)
        this.isLoading = false
      } else {
        this.confirmRestoreJob()
      }
    },

    showRestoreEventPopup() {
      this.restore_event_popup = true
    },
    confirmRestoreRecord(item, restoreType) {
      let confirmMessage = `You are about to restore the following ${this.default_name.toLowerCase()} : \n ${this.params.data.name}`
      let okTitle = `Restore ${this.default_name}`
      if (restoreType === 'restore-event-jobs') {
        confirmMessage = `You are about to restore the following ${this.default_name.toLowerCase()} along with Jobs : \n ${this.params.data.name}`
        okTitle = `Restore ${this.default_name} and Jobs`
      }

      this.$bvModal
        .msgBoxConfirm(
          confirmMessage,
          {
            title: 'Confirm Restore Alert',
            size: 'sm',
            okVariant: 'success',
            okTitle,
            cancelTitle: 'Cancel',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          },
        )
        .then(value => {
          if (value) {
            this.restore_event(restoreType)
          }
        })
    },
    restore_event(restoreType) {
      this.isLoading = true
      this.$http
        .put(`/api/event/${this.params.id}/${restoreType}`, {})
        .then(response => {
          const { success } = response.data

          if (success) {
            this.$toastSuccess('Event Manager Alert', `Your ${this.default_name} has been restored successfully`)

            this.restore_event_popup = false

            this.$emit('refresh-records', true)
            // window.location.reload()
          } else {
            this.$toastDanger('Event Manager Alert', 'Event restore failed.')
          }

          this.isLoading = false
        })
        .catch(error => {
          this.$handleErrorResponse(error, 'Event Manager Alert', 'Event restore failed.')
          this.isLoading = false
        })
    },

    confirmRestoreJob() {
      this.$bvModal
        .msgBoxConfirm(
          `You are about to restore the following ${this.default_name.toLowerCase()} : \n ${this.params.data.position}`,
          {
            title: 'Confirm Closure Alert',
            size: 'sm',
            okVariant: 'success',
            okTitle: `Restore ${this.default_name}`,
            cancelTitle: 'Cancel',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          },
        )
        .then(value => {
          if (value) {
            this.restore_job()
          }
        })
    },

    restore_job() {
      this.$http
        .put(`/api/job/${this.params.id}/restore`)
        .then(response => {
          const { success, message } = response.data

          if (success) {
            this.$toastSuccess('Event Manager Alert', `Your ${this.default_name} has been restored successfully`)

            this.$emit('refresh-records', true)
          } else {
            this.$toastDanger('Event Manager Alert', `${this.default_name} restore failed.`)
          }
        })
        .catch(error => {
          this.$handleErrorResponse(error, 'Event Manager Alert', `${this.default_name} restore failed.`)
        })
    },

    showParticipatingEvents() {
      // this.$router.push({ name: 'participating-jobs', params: { event_id: this.params.id } })
      this.$router.push(
        `/events-manager/participating-events/${this.params.id}`,
      )
    },

    refreshData() {
      const join_event_id = this.$route.params.event_id

      if (join_event_id || this.params.table === 'events_jobs') {
        this.default_name = 'Job'
      } else {
        this.default_name = 'Event'
      }
    },
  },
}
</script>

<style type="scss" scoped>
  .grid-btn{
    margin: 2px 2px;
  }
</style>
